import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import { kebabCase } from 'lodash'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const page = this.props.pageContext
    const tag = page.tag
    const title = this.props.data.site.siteMetadata.title

    return (
      <Layout>
        <section className="section tag-page-section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h3 className="title is-size-4 is-bold-light tag-title">{tag}</h3>
                <div className="content">
                  <div className="column is-12">
                    <div className="columns is-multiline">
                      {posts &&
                        posts.map(({ node: post }) => (
                          <div className="is-parent column is-4" key={post.id}>
                            <article
                              className={`blog-list-item tile is-child box notification ${
                                post.frontmatter.featuredpost ? 'is-featured' : ''
                              }`}
                            >
                              <header>
                                <div className="featured-hover">
                                  {post.frontmatter.featuredimage ? (
                                    <div className="featured-thumbnail">
                                      <Link
                                        to={post.fields.slug}
                                      >
                                        <PreviewCompatibleImage
                                          imageInfo={{
                                            image: post.frontmatter.featuredimage,
                                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                          }}
                                          imageStyles={{
                                            borderRadius_prop: '0px',
                                            width_prop: '100%',
                                            height_prop: '12rem'
                                          }}
                                        />
                                      </Link>
                                    </div>
                                  ) : null}
                                  <div className="post-meta">
                                    <Link
                                      to={post.fields.slug}
                                    >
                                      <span className="date is-size-7 card-date is-block">
                                        {post.frontmatter.date}
                                      </span>
                                      <p className="title has-text-title is-size-6">
                                        {post.frontmatter.title}
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                                <div className="tags">
                                  {post.frontmatter.tags.map((tag, index) => (
                                    <Link
                                      className="card-tag"
                                      to={`/tags/${kebabCase(tag)}/`}
                                      key = {index}
                                    >
                                      #{tag}
                                    </Link>
                                  ))}
                                </div>
                              </header>
                            </article>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="pagination-section">
          <ul className="pagination">
            {page.tagIsFirst && (
              <li className="prev prev-dummy">
                <Link><span></span></Link>
              </li>
            )}
            {!page.tagIsFirst && (
              <li className="prev">
                <Link 
                  className="pagination-button"
                  to={
                    page.tagCurrentPage === 2
                      ? `/tags/${kebabCase(tag)}/`
                      : `/tags/${kebabCase(tag)}/${page.tagCurrentPage - 1}/`
                  }
                  rel="prev"
                  >
                  <span>← Newer</span>
                </Link>
              </li>
            )}
            <li className="page-number">{page.tagCurrentPage}&nbsp;&nbsp;/&nbsp;&nbsp;{page.tagPages}</li>
            {page.tagIsLast && (
              <li className="next next-dummy">
                <Link><span></span></Link>
              </li>
            )}
            {!page.tagIsLast && (
              <li className="next">
                <Link 
                  className="pagination-button"
                  to={`/tags/${kebabCase(tag)}/${page.tagCurrentPage + 1}/`}
                  rel="next"
                  >
                  <span>Older →</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String, $tagSkip: Int!, $tagLimit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      skip: $tagSkip
      limit: $tagLimit
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            tags
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 240, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
